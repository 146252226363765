<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <h4>FUENTES DE INGRESO</h4>
        <app-table-registers
          ref="tr"
          :getList="getList"
          @btnNew="
            $refs.formSource.reset();
            $refs.dFormSource.show();
          "
        >
          <template slot="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>FECHA</th>
                <th>NOMBRE</th>
                <th>AREA</th>
                <th class="text-right">META</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in list.data" :key="l.id">
                <td>{{ l.id }}</td>
                <td>{{ l.created_at | dateTimeFormat }}</td>
                <td>{{ l.name }}</td>
                <td>{{ l.area_name }}</td>
                <td class="text-right">
                  <app-span-money
                    v-if="l.budget"
                    :quantity="l.budget.max"
                    :moneyCode="l.budget.money_code"
                  ></app-span-money>
                </td>
                <td class="text-right">
                  <div class="btn-group">
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.goalForm.setSourceId(l.id);
                        $refs.elGoalForm.show();
                      "
                    >
                      <i class="fa fa-flag"></i>
                    </button>
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.formSource.loadReg(l);
                        $refs.dFormSource.show();
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.dDelSource.val = l.id;
                        $refs.dDelSource.show();
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic idModal="dFormSource" ref="dFormSource">
      <h4>FORMULARIO DE FUENTE DE INGRESO</h4>
      <sourceForm
        ref="formSource"
        @submitted="
          $refs.dFormSource.hide();
          getList();
        "
      ></sourceForm>
    </app-modal-basic>

    <app-modal-yn
      ref="dDelSource"
      @no="$refs.dDelSource.hide()"
      @yes="deleteItem($refs.dDelSource.val)"
    >
      <p>
        Seguro(a) de eliminar el registros
        <b>{{ $refs.dDelSource && $refs.dDelSource.val }}</b
        >?
      </p>
    </app-modal-yn>

    <app-modal-basic ref="elGoalForm">
      <h4>MODIFICAR PRESUPUESTO</h4>
      <goal-form
        ref="goalForm"
        @submitted="
          $refs.elGoalForm.hide();
          $refs.tr.gl();
        "
      ></goal-form>
    </app-modal-basic>

    <groups></groups>
  </div>
</template>

<script>
import Form from "./Form.vue";
import groups from "./groups";
import { AdminService } from "../AdminService";
import goalForm from "./GoalForm";

export default {
  components: {
    sourceForm: Form,
    groups,
    goalForm
  },
  data: () => ({
    list: {}
  }),

  methods: {
    deleteItem(sourceId) {
      AdminService.deleteSource(sourceId).then(() => {
        this.$refs.dDelSource.hide();
        this.$refs.tr.gl();
      });
    },
    getList(params) {
      return new Promise((rsv) => {
        AdminService.getSources(params).then((res) => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
